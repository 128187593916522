/* src/root/advanced/Library.css */

.library-card {
    background-color: #2c3e50; /* Dark blue-gray background to match the image */
}

.library-card .card-title,
.library-card .card-subtitle,
.library-card .card-text {
    color: white !important; /* Force white color for all text */
}

.library-card .card-subtitle {
    opacity: 0.8; /* Slightly dimmed subtitle for hierarchy */
}
