.fc .fc-col-header-cell-cushion {
    background-color: #34495e !important;
    color: white !important;
    padding: 5px !important;
    width: 100% !important;
    display: inline-block !important;
}

.fc .fc-col-header-cell {
    background-color: #34495e !important;
}
