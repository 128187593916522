.react-flow__background {
    background-color: #495057 !important;
}

.interactive-graph-container {
    background-color: #495057 !important;
}

.custom-node {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    text-align: center;
}

.custom-node[class*="Source"] {
    background-color: #34495e;
    color: white;
}

.custom-node[class*="bundle"] {
    background-color: #34495e;
    color: white;
}

.custom-node[class*="System"][class*="Report"] {
    background-color: rgb(145, 7, 145);
    color: white;
}

.custom-node[class*="System"][class*="Collection"] {
    background-color: rgb(97, 11, 97);
    color: white;
}

.custom-node[class*="Context"] {
    background-color: black;
    color: white;
}

.custom-node[class*="Date"] {
    background-color: blue;
    color: white;
}

.custom-node[class*="Chat"] {
    background-color: #dd7e5b;
    color: white;
}

.custom-node[class*="ToDo"] {
    background-color: yellow;
    color: white;
}

.custom-node[class*="Assignment"] {
    background-color: #dcdc6a;
    color: white;
}

.custom-node[class*="Application"] {
    background-color: #e74c3c;
    color: white;
    font-weight: bold;
}

.custom-node[class*="Recipe"] {
    background-color: #c15bdd;
    color: white;
    font-weight: bold;
    border: 2px solid #d33682;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.custom-node[class*="Meal"] {
    background-color: #c15bdd;
    color: white;
    font-weight: bold;
    border: 2px solid #2029d1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.custom-node[class*="Food"] {
    background-color: #469038;
    color: white;
    font-weight: bold;
    border: 2px solid #07bc0c;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.custom-node[class*="Food_Staple"] {
    background-color: #469038;
    color: white;
    font-weight: bold;
    border: 2px solid #e65100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.custom-node[class*="Date"] {
    background-color: #30d539;
    color: white;
    font-weight: bold;
}

.custom-node[class*="Terminology"] {
    background-color: #dd7e5b;
    color: white;
    font-weight: bold;
}

.custom-node[class*="Question"] {
    background-color: #c15bdd;
    color: white;
    font-weight: bold;
}

.custom-node[class*="Root"] {
    background-color: rgb(141, 0, 0);
    color: white;
    font-weight: bold;
}

.custom-node[class*="Collection"] {
    background-color: rgb(38, 39, 48);
    color: white;
}

.react-flow__handle {
    width: 15px !important;
    height: 15px !important;
    border-radius: 50% !important;
    background: #e74c3c !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.react-flow__handle-top {
    transform: translate(-50%, -10px) !important;
}

.react-flow__handle-bottom {
    transform: translate(-50%, 10px) !important;
}

.interactive-graph-container {
    position: relative;
    height: 600px;
    width: 100%;
    overflow: hidden;
}


.react-flow__edge-interaction, .react-flow__edge-path, .edge-default {
    stroke: #ffffff !important;
    stroke-width: 2 !important;
}


.application-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.pretty-button {
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.pretty-button:not(.selected) {
    background-color: #34495e;
    color: #ecf0f1;
}

.pretty-button:not(.selected):hover {
    background-color: #2c3e50;
}

.pretty-button.selected {
    background-color: #3498db;
    color: #ffffff;
}

.pretty-button.selected:hover {
    background-color: #2980b9;
}

